<template>
  <Card>
    <template v-slot:header>
      <div class="steps">
        <img src="@/assets/images/idPoa/step01.png" alt="" />
        <div class="line"></div>
        <img src="@/assets/images/idPoa/step02.png" alt="" />
        <div class="line"></div>
        <img src="@/assets/images/idPoa/step03.png" alt="" />
      </div>
      <span>{{ $t('notification.pendingIdentityProof.header') }}&nbsp;&nbsp;</span>
    </template>
    <div class="text">
      <i18n path="notification.pendingIdentityProof.content" tag="p">
        <template v-slot:doc>{{ doc }}</template>
        <template v-slot:doc2>{{ doc2 }}</template>
      </i18n>
      <router-link :to="{ name: 'depositFunds' }" class="el-button btn-red">
        {{ $t('register.btn.fundnow') }}
      </router-link>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/Card';

export default {
  name: 'notification',
  components: { Card },
  props: { id: Boolean, poa: Boolean },
  data() {
    return {
      doc2: ''
    };
  },
  computed: {
    doc() {
      let doc;
      if (this.id && this.poa) {
        this.doc2 = this.$t('notification.pendingIdentityProof.both2');
        doc = this.$t('notification.pendingIdentityProof.both');
      } else if (this.id) {
        this.doc2 = this.$t('notification.pendingIdentityProof.single');
        doc = this.$t('notification.pendingIdentityProof.id');
      } else if (this.poa) {
        this.doc2 = this.$t('notification.pendingIdentityProof.single');
        doc = this.$t('notification.pendingIdentityProof.poa');
      }

      return doc;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/notification.scss';
</style>
