<template>
  <el-dialog :visible.sync="showCopyTradeDialog" class="copyTrade" :show-close="false">
    <div class="dialog-content">
      <a class="close-area" @click="showCopyTradeDialog = false"></a>
      <div class="show-in-desktop">
        <img src="@/assets/images/copytrade-web.png" usemap="#image-map-web" />

        <div class="copy-trade-header" :class="lang">
          <div class="title">{{ $t('copyTradingBanner.header') }}</div>
          <div class="desc">{{ $t('copyTradingBanner.desc') }}</div>
        </div>

        <div class="download">{{ $t('copyTradingBanner.download') }}</div>
        <map name="image-map-web">
          <area
            target="_blank"
            alt="Download on the AppStore"
            title="Download on the AppStore"
            href="https://puprime.onelink.me/O5Jx/APPCLIENTPORTAL"
            coords="70,449,210,492"
            shape="rect"
          />
          <area
            target="_blank"
            alt="Get It On Google Play"
            title="Get It On Google Play"
            href="https://puprime.onelink.me/O5Jx/MAINAPPINSTALLANDROID"
            coords="214,449,354,491"
            shape="rect"
          />
        </map>
      </div>
      <div class="show-in-mobile">
        <img src="@/assets/images/copytrade-mobile.png" usemap="#image-map-mobile" />

        <div class="copy-trade-header" :class="lang">
          <div class="title">{{ $t('copyTradingBanner.header') }}</div>
          <div class="desc">{{ $t('copyTradingBanner.desc') }}</div>
        </div>

        <map name="image-map-mobile">
          <area
            target="_blank"
            alt="Download on the AppStore"
            title="Download on the AppStore"
            href="https://puprime.onelink.me/O5Jx/APPCLIENTPORTAL"
            coords="171,490,32,447"
            shape="rect"
          />
          <area
            target="_blank"
            alt="Get It On Google Play"
            title="Get It On Google Play"
            href="https://puprime.onelink.me/O5Jx/MAINAPPINSTALLANDROID"
            coords="317,491,177,449"
            shape="rect"
          />
        </map>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'CopyTradeDialog',
  props: {
    showCopyTradeDialog: Boolean
  },
  data() {
    return {
      visible: false
    };
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  },
  watch: {
    showCopyTradeDialog(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:showCopyTradeDialog', bool);
    }
  }
};
</script>

<style lang="scss" scoped>
.copy-trade-header {
  position: absolute;
  height: 40%;
  top: 0;
  padding: 60px 10% 0;
  @include rtl-sass-value(text-align, left, right);

  .title {
    font-size: 40px;
    font-weight: 900;
    line-height: 1.25;
    padding-bottom: 10px;
  }
  .desc {
    width: 75%;
    font-size: 16px;
    font-weight: 400;
  }

  @media (min-width: 1024px) {
    .title {
      font-size: 38px;
    }
  }

  // by lang
  &.fr_FR,
  &.es,
  &.pt,
  &.ja,
  &.vi,
  &.id {
    padding-top: 0;
    align-content: end;
    .title {
      font-size: 38px;
      @media (max-width: 1024px) {
        font-size: 36px;
      }
    }
  }

  // too long
  &.id {
    .title {
      font-size: 36px;
      @media (max-width: 1024px) {
        font-size: 32px;
      }
    }
  }

  @media (max-width: 420px) {
    padding-top: 0 !important;
    align-content: center !important;
    .title {
      font-size: 26px !important;
    }
    .desc {
      font-size: 14px !important;
    }
  }
  @media (max-width: 350px) {
    .title {
      font-size: 22px !important;
    }
    .desc {
      font-size: 12px !important;
    }
  }
}

.show-in-desktop {
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }

  .download {
    font-weight: 900;
    font-size: 20px;
    width: 120px;
    line-height: 1.3;
    text-align: center;
    position: absolute;
    right: 40px;
    bottom: 135px;
  }
}

.show-in-mobile {
  display: block;

  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.copyTrade {
  .dialog-content {
    color: #fff;
    word-break: normal;
    position: relative;
  }

  .close-area {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 1;
    display: inline-block;
    width: 36px;
    height: 36px;
  }

  /deep/ .el-dialog {
    background: transparent;
    box-shadow: none;
    max-width: 388px;
    margin-top: 15vh !important;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__wrapper {
      padding: 0;
    }

    @media screen and (min-width: 1024px) {
      max-width: 440px;
    }
  }
}
</style>
