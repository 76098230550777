<template>
  <card class="notice-container">
    <template v-slot:header>
      <div v-if="noticeStyle === 'noSubmitted'">
        <img src="@/assets/images/dialog_info.png" alt />
      </div>

      <div v-if="noticeStyle === 'process'">
        <div class="steps">
          <img src="@/assets/images/idPoa/step01.png" alt="" />
          <div class="line"></div>
          <img src="@/assets/images/idPoa/step02.png" alt="" />
          <div class="line"></div>
          <img src="@/assets/images/idPoa/step03.png" alt="" />
        </div>
        <span>{{ $t('notification.pendingIdentityProof.header') }}&nbsp;&nbsp;</span>
      </div>

      <div v-if="noticeStyle === 'alert'">
        <span>{{ $t('identityProof.home.header') }}&nbsp;&nbsp;</span>
        <img src="@/assets/images/error_red_icon.png" alt />
      </div>
    </template>

    <div class="text">
      <div v-if="noticeStyle === 'noSubmitted'">
        <div>{{ $t('register.authority.dialog1') }}</div>
        <div>{{ $t('register.authority.dialog2') }}</div>
        <router-link :to="{ name: 'register' }" class="el-button btn-red">
          {{ $t('register.authority.openLiveAcc') }}
        </router-link>
      </div>

      <div v-if="noticeStyle === 'process'">
        <i18n path="notification.pendingIdentityProof.content" tag="p">
          <template v-slot:doc>{{ doc }}</template>
        </i18n>
        <router-link :to="{ name: 'depositFunds' }" class="el-button btn-red">
          {{ $t('register.btn.fundnow') }}
        </router-link>
      </div>

      <div v-if="noticeStyle === 'alert'">
        <i18n path="identityProof.home.content" tag="p">
          <template v-slot:name>{{ userName }}</template>
        </i18n>
        <router-link :to="{ name: 'register' }" class="el-button btn-red">
          {{ $t('common.button.identityProof') }}
        </router-link>
      </div>
    </div>
  </card>
</template>

<script>
import Card from '@/components/Card';
import { mapState, mapGetters } from 'vuex';
import { statusConfig } from '../../constants/status';
import { checkRegisterPageCanAudit } from '../../util/register';

export default {
  name: 'RegisterNotice',
  components: { Card },
  props: {
    page: {
      type: String,
      default: '' // withdraw (withdraw page has special logic to show notices)
    }
  },
  computed: {
    ...mapState('register', ['accountAuditStatus', 'poiAuditStatus', 'poaAuditStatus']),
    ...mapGetters('register', ['idPass', 'poaPass', 'isPoiUnderReview', 'isPoaUnderReview']),
    noticeStyle() {
      // All Types : noSubmitted, alert, process, notShow
      if (this.accountAuditStatus === statusConfig.rejected || (this.idPass && this.poaPass)) return 'notShow';

      if (this.poiAuditStatus === statusConfig.init || this.poaAuditStatus === statusConfig.init) {
        return this.accountAuditStatus !== statusConfig.init && this.accountAuditStatus !== statusConfig.rejected
          ? 'alert'
          : 'noSubmitted';
      }

      if (this.accountAuditStatus === statusConfig.completed && this.poiAuditStatus === statusConfig.completed) {
        if (checkRegisterPageCanAudit(this.poaAuditStatus)) return 'alert';
        return this.page === 'withdraw' ? 'process' : 'notShow';
      }
      if (!checkRegisterPageCanAudit(this.poiAuditStatus) && !checkRegisterPageCanAudit(this.poaAuditStatus)) {
        return 'process';
      }

      return 'alert';
    },
    doc() {
      let doc;
      if (this.isPoiUnderReview && this.isPoaUnderReview) {
        doc = this.$t('notification.pendingIdentityProof.both');
      } else if (this.isPoiUnderReview) {
        doc = this.$t('notification.pendingIdentityProof.id');
      } else if (this.isPoaUnderReview) {
        doc = this.$t('notification.pendingIdentityProof.poa');
      }
      return doc;
    },
    userName() {
      return this.$store.state.common.individualUserName;
    }
  },
  watch: {
    noticeStyle: {
      immediate: true,
      handler(newValue) {
        if (newValue === 'notShow') this.$emit('hideNotice', true);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/notification.scss';
.notice-container {
  margin-bottom: 24px;
}
</style>
