<template>
  <!-- active 弹框开始 -->
  <div class="active-dialog">
    <DialogVersion3 :visible.sync="visible" visibleFooter>
      <p>{{ $t('home.activate.question') }}</p>
      <div class="btn-box">
        <el-button class="btn-blue" @click="activeConfirmClick" data-testid="activeConfirmClick">
          {{ $t('common.button.confirm') }}
        </el-button>
        <el-button class="btn-default" @click="disableActivateVisible(false)" data-testid="disableActivateVisible">
          {{ $t('common.button.cancel') }}
        </el-button>
      </div>
    </DialogVersion3>
  </div>
  <!-- active 弹框结束 -->
</template>

<script>
import DialogVersion3 from '@/components/dialog/v3/Dialog';
import { apiReactivate_Account } from '@/resource';

export default {
  name: 'active',
  props: {
    uuid: [Number, String],
    reActivateMetaTraderLogin: Number,
    activateVisible: Boolean,
    activateConfirmVisible: Boolean
  },
  components: { DialogVersion3 },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    activateVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.disableActivateVisible(bool);
    }
  },
  methods: {
    activeConfirmClick() {
      this.reActivateAccount();
      this.disableActivateVisible(false);
      this.$emit('update:activateConfirmVisible', true);
    },
    disableActivateVisible(bool) {
      this.$emit('update:activateVisible', bool);
    },
    reActivateAccount() {
      apiReactivate_Account({
        user_id: this.uuid,
        metaTraderLogin: this.reActivateMetaTraderLogin
      })
        .then(resp => {
          this.$emit('resetTable');
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/active.scss';
</style>
