<template>
  <Card>
    <template v-slot:header>
      <span>{{ header }}&nbsp;&nbsp;</span>
      <img src="@/assets/images/error_red_icon.png" alt />
    </template>
    <div class="text">{{ content }}</div>
    <el-button class="btn-red" @click="confirm" data-testid="confirm">
      {{ btnText }}
    </el-button>
  </Card>
</template>

<script>
import Card from '@/components/Card';

export default {
  name: 'pendingAction',
  components: { Card },
  props: {
    type: {
      type: String,
      default: 'identityProof' // identityProof, poaNotice, questionnaire
    }
  },
  computed: {
    header() {
      return this.type === 'identityProof'
        ? this.$t('identityProof.home.header')
        : this.$t('identityProof.home.poaRequired.header');
    },
    content() {
      return this.type === 'identityProof'
        ? this.$t('identityProof.home.content', {
            name: this.$store.state.common.individualUserName
          })
        : this.$t('identityProof.home.poaRequired.content');
    },
    btnText: function() {
      switch (this.type) {
        case 'questionnaire':
          return this.$t('common.button.completeQuestionnaire');
        case 'poaNotice':
          return this.$t('common.button.verifyNow');
        default:
          return this.$t('common.button.identityProof');
      }
    }
  },
  methods: {
    confirm() {
      this.$router.push({ name: 'register' }).catch(err => {});
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/pendingAction.scss';
</style>
