export default {
  data() {
    return {
      hideIdList: [],
    }
  },
  methods: {
    onHideAccount() {
      const getHideIdList = this.accountTableData.filter(f => this.hideIdList.includes(f.id)).map(m => m.id)
      this.hideAccount(getHideIdList)
    },
    hideAccount(getHideIdList) {
      this.resetCheckList()
      this.queryMetaTraderAccountDetails(getHideIdList, false)
    },
    showAllAccount() {
      this.queryMetaTraderAccountDetails([], true)
    },
    resetCheckList() {
      this.hideIdList = []
    },
  },
}
