<template>
  <home :showLink="showLink">
    <template slot="alerts">
      <!-- <PendingAction v-if="isPoiEditable" :type="isPoiEditable ? 'poaNotice' : 'identityProof'" />
      <Notification v-else-if="isPoiUnderReview" :id="isPoiUnderReview" /> -->
      <RegisterNotice v-if="!isHideNotice" @hideNotice="hideNotice" />
    </template>
    <PropTradingDashboardTopBanner v-if="showPropTrade" />
    <div class="table-list-box">
      <h3>{{ $t('menu.liveAcc') }} ＞＞＞</h3>
      <div class="hide-show-button">
        <el-button class="btn-blue" @click="onHideAccount()" data-testid="hideAccount">
          {{ $t('common.button.hide') }}
        </el-button>
        <el-button class="btn-default" @click="showAllAccount()" data-testid="showAllAccount">
          {{ $t('common.button.showAll') }}
        </el-button>
      </div>
      <div class="table-item-box">
        <div class="table-box">
          <el-table :data="accountTableData">
            <el-table-column show-overflow-tooltip :label="$t('common.button.hide')" min-width="120">
              <template slot-scope="scope">
                <div class="hideAccountCheckbox">
                  <el-checkbox v-model="hideIdList" :label="scope.row.id" :value="scope.row.id"></el-checkbox>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="mt4_account"
              :label="$t('common.column.accNum')"
              min-width="150"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="server"
              :label="$t('common.column.server')"
              min-width="180"
            ></el-table-column>
            <el-table-column show-overflow-tooltip :label="$t('common.column.type')" min-width="180">
              <template slot-scope="scope">
                {{ $config.accountTypeMaps[scope.row.mt4_account_type] }}
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip :label="$t('common.column.PLATFORM')" min-width="150">
              <template slot-scope="scope">
                {{ scope.row.serverCategory == '4' ? `MT4` : scope.row.serverCategory == '5' ? 'MT5' : 'Copy Trading' }}
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="currency"
              :label="$t('common.column.currency')"
              min-width="120"
            ></el-table-column>
            <el-table-column show-overflow-tooltip :label="$t('common.column.equity')" min-width="130">
              <template slot-scope="scope">{{ scope.row.equity | formatNumber }}</template>
            </el-table-column>
            <el-table-column show-overflow-tooltip min-width="130">
              <template slot="header">
                <div class="creditsBox">
                  <span>{{ $t('common.column.credits') }}</span>
                  <el-popover trigger="hover" width="300" placement="bottom-end" popper-class="creditsTip">
                    <i slot="reference" class="el-icon-warning credits_popover"></i>
                    <div>{{ $t('home.explanation') }}</div>
                  </el-popover>
                </div>
              </template>
              <template slot-scope="scope">
                <span v-if="scope.row.status === 'Active'">{{ scope.row.credit | formatNumber }}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip :label="$t('common.column.balance')" min-width="130">
              <template slot-scope="scope">
                <span v-if="scope.row.status === 'Active'">{{ scope.row.balance | formatNumber }}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <!-- <el-table-column show-overflow-tooltip :label="$t('common.column.leverage')" min-width="170">
              <template slot-scope="scope">
                <div v-if="scope.row.status == 'Active'" class="leveralContainer">
                  <span>{{ scope.row.leverage }} : 1</span>
                  <div class="icon" v-if="!scope.row.isInBlackList">
                    <a class="paddingLeft" v-if="scope.row.leverageStatus == 1">
                      <img
                        src="@/assets/images/menu/down.png"
                        @click.stop.prevent="leverageClick(scope.$index, scope.row)"
                        alt="img"
                        data-testid="leverageMt4"
                      />
                    </a>
                    <a class="paddingLeft" v-else>
                      <img
                        src="@/assets/images/label_icon.png"
                        @click.stop.prevent="showleverageAlreadyInProcessPopUp()"
                        alt="img"
                        data-testid="leverageAlreadyInProcessPopUp"
                      />
                    </a>
                  </div>
                  <el-popover v-if="scope.row.tag" class="icon" placement="bottom-start" width="260" trigger="hover">
                    <i class="el-icon-question question-icon" slot="reference"></i>
                    <div class="popover-context">{{ $t('home.leverage.autoRestore') }}</div>
                  </el-popover>
                </div>
                <i v-else>-</i>
              </template>
            </el-table-column> -->
            <el-table-column
              show-overflow-tooltip
              v-if="!showDiffTable"
              :label="$t('common.column.status')"
              min-width="120"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.status == 'Active'" :class="'blue'">
                  {{ scope.row.status | accStatus }}
                </div>
                <div v-if="scope.row.status == 'Inactive'" :class="'gray'">
                  <i>{{ scope.row.status | accStatus }}</i>
                </div>
                <div v-if="scope.row.status == 'Processing' || scope.row.status == 'Rejected'" :class="'gray'">
                  <i>{{ scope.row.status | accStatus }}</i>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip v-else :label="$t('common.column.status')" min-width="120">
              <div>
                <span class="leverageTextLeft red"><i>pending</i></span>
                <div class="icon">
                  <a class="paddingLeft">
                    <el-tooltip
                      :content="$t('home.pendingTooltip', { number: 4 })"
                      placement="bottom"
                      popper-class="homeTooltip"
                    >
                      <img src="@/assets/images/label_icon.png" class="label-icon" />
                    </el-tooltip>
                  </a>
                </div>
              </div>
            </el-table-column>
            <el-table-column v-if="!showDiffTable && !showDiffButton">
              <template slot-scope="scope">
                <div v-if="scope.row.status == 'Active'">
                  <el-dropdown trigger="click">
                    <img src="@/assets/images/btn-more.png" class="button-more" />
                    <el-dropdown-menu
                      slot="dropdown"
                      :class="{ 'copy-trading-dropdown': scope.row.serverCategory == 6 }"
                    >
                      <el-dropdown-item
                        v-if="scope.row.status == 'Active' && !scope.row.isInBlackList"
                        class="leverage"
                      >
                        <div class="inner-item">
                          <div @click.stop.prevent="leverageClick(scope.$index, scope.row)">
                            {{ $t('common.button.changeLeverage2') }}
                          </div>
                          <el-popover
                            v-if="scope.row.serverCategory == 6"
                            trigger="hover"
                            width="300"
                            placement="bottom-end"
                          >
                            <i slot="reference" class="el-icon-warning" @click.stop.prevent></i>
                            <div class="word-break">{{ $t('home.copyTrading.actionTooltip') }}</div>
                          </el-popover>
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item v-if="scope.row.serverCategory == '6'">
                        <div class="inner-item" @click="copyTrade()">{{ $t('common.button.copyTrade') }}</div>
                      </el-dropdown-item>
                      <!-- resetTypeDisplay: 用于判断是否显示重置按钮，有三个值，1为不显示，2为显示，3为显示但是不可点击 -->
                      <el-dropdown-item v-if="scope.row.resetTypeDisplay && scope.row.resetTypeDisplay === 2">
                        <div class="inner-item" @click="onResetBalance(scope.row)" data-testid="resetBalance">
                          {{ $t('common.button.resetBalance') }}
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item v-if="scope.row.status == 'Active'" class="reset-password">
                        <div class="inner-item">
                          <div @click.stop.prevent="handleLock(scope.$index, scope.row)" data-testid="resetMT4PW">
                            {{ $t('common.button.resetPassword') }}
                          </div>
                          <el-popover
                            v-if="scope.row.serverCategory == 6"
                            trigger="hover"
                            width="300"
                            placement="bottom-end"
                          >
                            <i slot="reference" class="el-icon-warning" @click.stop.prevent></i>
                            <div class="word-break">{{ $t('home.copyTrading.actionTooltip') }}</div>
                          </el-popover>
                        </div>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column
              show-overflow-tooltip
              v-if="!showDiffTable && !showDiffButton"
              :label="$t('resetPassword.header')"
              min-width="150"
            >
              <template slot-scope="scope">
                <a
                  v-if="scope.row.status == 'Active'"
                  @click.stop.prevent="handleLock(scope.$index, scope.row)"
                  data-testid="resetMT4PW"
                >
                  <img src="@/assets/images/home/lock_icon.png" alt />
                </a>
                <i v-else>-</i>
              </template>
            </el-table-column> -->
            <el-table-column
              show-overflow-tooltip
              v-else-if="showDiffButton"
              :label="$t('common.button.depositFunds')"
              min-width="200"
            >
              <el-button class="btn-blue" @click="$router.push('depositFunds')" data-testid="depositFund">
                {{ $t('common.button.depositFunds') }}
              </el-button>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <template slot="components"
      ><!-- CHANGE LEVERAGE 弹框开始 -->
      <ChangeLeverage
        :changeLeverageVisible.sync="changeLeverageVisible"
        :leverageConfirmVisible.sync="leverageConfirmVisible"
        :leverageChangeSubmitSuccess.sync="leverageChangeSubmitSuccess"
        :leverageChangeSubmitFail.sync="leverageChangeSubmitFail"
        :leverageChangeSubmitReject.sync="leverageChangeSubmitReject"
        :leverageChangeAuditSuccess.sync="leverageChangeAuditSuccess"
        :leverageChangeSubmitTimeOut.sync="leverageChangeSubmitTimeOut"
        @resetTable="queryMetaTraderAccountDetails"
        method="live"
      ></ChangeLeverage>
      <!-- CHANGE LEVERAGE 弹框结束 -->

      <!-- CHANGE LEVERAGE confirm 弹框开始 -->
      <Common :homeVisible.sync="leverageConfirmVisible">
        <div class="dialog-body" v-if="leverageChangeSubmitSuccess">
          <img src="@/assets/images/dialog_smile.png" alt />
          <p
            v-html="
              leverageChangeAuditSuccess
                ? $t('home.leverage.changeLeverageAutoComplete')
                : $t('home.leverage.changeLeverageConfirm')
            "
          ></p>
        </div>
        <div class="dialog-body" v-if="leverageChangeSubmitFail">
          <img src="@/assets/images/dialog_false.png" alt />
          <p v-html="$t('home.leverage.changeLeverageFail')"></p>
        </div>
        <div class="dialog-body" v-if="leverageChangeSubmitReject">
          <img src="@/assets/images/dialog_false.png" alt />
          <p v-html="$t('home.leverage.changeLeverageReject')"></p>
        </div>
        <div class="dialog-body" v-if="leverageChangeSubmitTimeOut">
          <img src="@/assets/images/dialog_false.png" alt />
          <p
            v-html="
              $t('home.leverage.changeLeverageDefault', {
                supportEmail: GLOBAL_CONTACT_EMAIL
              })
            "
          ></p>
        </div>
      </Common>
      <!-- CHANGE LEVERAGE confirm 弹框结束 -->

      <!-- CHANGE LEVERAGE already 弹框开始 -->
      <Common :homeVisible.sync="leverageAlreadyVisible">
        <img src="@/assets/images/dialog/dialog_true.png" alt />
        <p>{{ $t('home.leverage.alreadyApplied', { platform: $config.info.fullName }) }}</p>
      </Common>
      <!-- CHANGE LEVERAGE already 弹框结束 -->

      <!-- 点击小锁弹框开始 -->
      <Passwork
        :passwordVisible.sync="passwordVisible"
        :forgotPwdVisible.sync="forgotPwdVisible"
        :number="number"
        :uuid="uuid"
        @forgotPwdClick="forgotPwdClick"
      ></Passwork>
      <!-- 点击小锁弹框结束 -->

      <!-- 点击 CHANGE PASSWORD  表单弹框开始 -->
      <ChangePassword
        :changePasswordVisible.sync="changePasswordVisible"
        :pwdConfirmVisible.sync="pwdConfirmVisible"
        :number="number"
        :isDemo="isDemo"
        @forgotPwdClick="forgotPwdClick"
      ></ChangePassword>
      <!-- 点击 CHANGE PASSWORD  表单弹框结束 -->

      <!-- CHANGE PASSWORD confirm 弹窗提示信息开始 -->
      <Common :homeVisible.sync="pwdConfirmVisible">
        <img src="@/assets/images/dialog/dialog_true.png" alt />
        <p v-html="$t('home.pw.confirm')"></p>
      </Common>
      <!-- CHANGE PASSWORD confirm 弹窗提示信息开始 -->

      <!-- FORGOT PASSWORD 开始 -->
      <Common :homeVisible.sync="forgotPwdVisible">
        <img src="@/assets/images/dialog/dialog_true.png" alt />
        <div v-html="$t('home.pw.email')"></div>
      </Common>
      <!-- FORGOT PASSWORD 结束 -->

      <!-- active 弹框开始 -->
      <Active
        :uuid="uuid"
        :reActivateMetaTraderLogin="reActivateMetaTraderLogin"
        :activateVisible.sync="activateVisible"
        :activateConfirmVisible.sync="activateConfirmVisible"
        @resetTable="queryMetaTraderAccountDetails"
      ></Active>
      <!-- active 弹框结束 -->

      <!-- active Confirm 弹框开始 -->
      <Common :homeVisible.sync="activateConfirmVisible">
        <img src="@/assets/images/dialog/dialog_true.png" alt />
        <p v-html="$t('home.activate.confirm')"></p>
      </Common>
      <!-- active Confirm 弹框结束 -->

      <DialogVersion3 :visible.sync="ndbDialog" extraClass="ndb_dialog">
        <div class="ndb_dialog_body">
          <p>{{ $t('promotion.notification.ndb.inst1') }}</p>
          <p v-html="$t('promotion.notification.ndb.inst2', { date: ndbDialogInfo.date })"></p>
          <p>{{ $t('promotion.notification.ndb.inst3') }}</p>
          <p
            v-html="$t('promotion.notification.ndb.inst4', { mail: ndbDialogInfo.mail, phone: ndbDialogInfo.phone })"
          ></p>
        </div>
      </DialogVersion3>

      <DialogVersion3 :visible.sync="dbDialog" extraClass="db_dialog">
        <div class="db_dialog_body">
          <p>{{ $t('promotion.notification.db.inst1') }}</p>
          <p v-html="$t('promotion.notification.db.inst2', { date: dbDialogInfo.date })"></p>
          <p>{{ $t('promotion.notification.db.inst3') }}</p>
          <p v-html="$t('promotion.notification.db.inst4', { mail: dbDialogInfo.mail, phone: dbDialogInfo.phone })"></p>
        </div>
      </DialogVersion3>
      <LiveResetBalance ref="liveResetBalanceRef" @confirm="handleResetBalance" />

      <CopyTradeDialog :showCopyTradeDialog.sync="showCopyTradeDialog" />
    </template>
  </home>
</template>

<script>
import {
  apiRestAccountBalance,
  apiQueryMetaTraderAccountDetails,
  apiQueryLeverageStatuses,
  apiReq_reset_mt4_password,
  apiParticipatedCampaigns,
  apiCampaignInfo,
  apiJoinedDepositBonusCampaign
} from '@/resource';
import DialogVersion3 from '@/components/dialog/v3/Dialog';
import PendingAction from '@/components/home/PendingAction';
import Notification from '@/components/home/Notification';
import Common from '@/components/home/Common';
import Active from '@/components/home/Active';
import Passwork from '@/components/home/Password';
import ChangePassword from '@/components/home/ChangePassword';
import ChangeLeverage from '@/components/home/ChangeLeverage';
import Home from '@/components/template/Home';
import PropTradingDashboardTopBanner from '@/components/propTrading/components/PropTradingDashboardTopBanner.vue';
import idPoaMixin from '@/mixins/idPoa';
import changeLeverageMixins from '@/mixins/page/home/changeLeverage';
import hideAccountMixins from '@/mixins/page/home/hideAccount';
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import RegisterNotice from '@/components/home/RegisterNotice.vue';
import LiveResetBalance from '@/components/home/LiveResetBalance.vue';
import CopyTradeDialog from '@/components/home/CopyTradeDialog.vue';

export default {
  components: {
    PendingAction,
    Notification,
    Common,
    Active,
    Passwork,
    ChangePassword,
    ChangeLeverage,
    Home,
    DialogVersion3,
    PropTradingDashboardTopBanner,
    RegisterNotice,
    LiveResetBalance,
    CopyTradeDialog
  },
  mixins: [idPoaMixin, changeLeverageMixins, hideAccountMixins],
  data() {
    return {
      uuid: this.$store.state.common.uuid,
      number: null,
      allMetaTraderAccounts: [],
      accountTableData: [],
      showMetaTraderAccounts: [],
      leverageStatus: {},
      leverageAlreadyVisible: false,
      passwordVisible: false,
      changePasswordVisible: false,
      // 小锁弹出框
      pwdConfirmVisible: false,
      forgotPwdVisible: false,
      // active 弹框
      activateVisible: false,
      activateConfirmVisible: false,
      reActivateMetaTraderLogin: null,
      isDemo: false,
      prevRoute: null,
      ndbDialog: false,
      ndbDialogInfo: {
        date: '',
        mail: '',
        phone: ''
      },
      dbDialog: false,
      dbDialogInfo: {
        date: '',
        mail: '',
        phone: ''
      },
      isHideNotice: false,
      resetAccountNumber: null,
      showCopyTradeDialog: false
    };
  },
  mounted() {
    this.actionUpdateId3Pass();
    this.queryMetaTraderAccountDetails();
  },
  computed: {
    id3Pass() {
      return this.$store.state.common.id3Pass;
    },
    showPropTrade() {
      return this.$store.state.propTrading.eligible;
    },
    showLink() {
      return this.allMetaTraderAccounts.length > 1;
    },
    showDiffTable() {
      return this.allMetaTraderAccounts.length == 1 && !this.id3Pass;
    },
    showDiffButton() {
      return (
        this.allMetaTraderAccounts.length == 1 && !this.id3Pass && this.allMetaTraderAccounts[0].mt4_account != '-'
      );
    },
    showResetColumn() {
      return this.accountTableData.some(item => item.resetTypeDisplay && item.resetTypeDisplay !== 1);
    },
    ...mapState('promotion', {
      eligibleCampaigns: 'eligibleCampaigns'
    })
  },
  watch: {
    eligibleCampaigns(newValue) {
      if (Array.isArray(newValue) && newValue.length > 0) {
        this.getNdbInfo();
      }
    }
  },
  beforeRouteEnter(_, from, next) {
    next(route => {
      route.prevRoute = from.name;
    });
  },
  methods: {
    ...mapActions('common', ['actionUpdateId3Pass']),
    async getNdbInfo() {
      if (this.prevRoute === 'login') {
        const noDepositBonusId = 2;
        const depositBonusId = 6;
        let noDepositBonus;
        let depositBonus;

        await apiParticipatedCampaigns().then(resp => {
          if (resp.data.code === 0) {
            noDepositBonus = resp.data.data.includes(noDepositBonusId);
          }
        });

        await apiJoinedDepositBonusCampaign().then(resp => {
          if (resp.data.code === 0 && resp.data.data) {
            depositBonus = resp.data.data.some(f => f.campaignId === depositBonusId);
          }
        });

        if (noDepositBonus) {
          await apiCampaignInfo(noDepositBonusId)
            .then(resp => {
              if (resp.data.code === 0 && resp.data.data.expiring) {
                this.ndbDialog = true;
                this.constructDialog(this.ndbDialogInfo, resp, this.ndbDialog);
              }
            })
            .catch(e => {
              console.log(e);
            });
        }

        if (depositBonus) {
          await apiCampaignInfo(depositBonusId)
            .then(resp => {
              if (resp.data.code === 0 && resp.data.data.expiring) {
                this.dbDialog = true;
                this.constructDialog(this.dbDialogInfo, resp, this.dbDialog);
              }
            })
            .catch(e => {
              console.log(e);
            });
        }
      }
    },
    constructDialog(dialogObj, resp) {
      dialogObj.mail = this.GLOBAL_CONTACT_EMAIL;
      dialogObj.phone = this.$config.info.getPhone(this.$store.state.common.regulator);
      const timeStamp = new Date(resp.data.data.expiredDate);
      dialogObj.date = moment.tz(timeStamp, 'Asia/Aden').format('DD/MM/YYYY');
    },
    queryMetaTraderAccountDetails(hiddenMtAccountIdList = [], unHide = false) {
      apiQueryMetaTraderAccountDetails({
        hiddenMtAccountIdList: hiddenMtAccountIdList,
        unHide: unHide
      }).then(resp => {
        if (resp.data && resp.data.length == 1 && resp.data[0].mt4_account) {
          this.account = resp.data[0].mt4_account;
          this.$store.commit('common/setOpenAccountAction', true);
        }
        this.allMetaTraderAccounts = resp.data;
        this.formatAccounts();
        this.queryLeverageStatus();
      });
    },
    formatAccounts() {
      this.allMetaTraderAccounts.forEach(element => {
        if (element.status != 1) {
          this.formatPendingAccount(element);
        }
        if (element.isArchive == 1 || element.isArchive == 2) {
          this.formatArchieveAccount(element);
        }
        this.setStatus(element);
      });
    },
    setStatus(account) {
      if (account.isArchive && (account.isArchive == 1 || account.isArchive == 2)) {
        account.status = 'Inactive';
        return;
      }
      if (account.status == 1) {
        account.status = 'Active';
        return;
      }
      if (account.status == 0) {
        account.status = 'Processing';
        return;
      }
      if (account.status == 2) {
        account.status = 'Rejected';
        return;
      }
    },
    queryLeverageStatus() {
      apiQueryLeverageStatuses({
        user_id: this.uuid
      }).then(resp => {
        this.leverageStatus = resp.data;
        this.updateLeverageStatus();
        this.accountTableData = this.allMetaTraderAccounts;
      });
    },
    updateLeverageStatus() {
      this.allMetaTraderAccounts.forEach(element => {
        if (element.mt4_account == '-' || element.mt4_account == null) {
          return;
        }
        if (this.leverageStatus[element.mt4_account.toString()]) {
          if (this.leverageStatus[element.mt4_account.toString()].status == 0) {
            element.leverageStatus = 0;
            return;
          }
        }
        element.leverageStatus = 1;
      });
    },
    formatPendingAccount(account) {
      account.equity = '-';
      account.mt4_account = '-';
    },
    formatArchieveAccount(account) {
      account.equity = '-';
      account.currency = '-';
    },
    showleverageAlreadyInProcessPopUp() {
      this.leverageAlreadyVisible = true;
    },
    // 点击小锁 第一个弹窗
    handleLock(index, row) {
      if (row.serverCategory == 6) return; // copy trading cp not allow to change password
      this.passwordVisible = true;
      this.number = row.mt4_account;
    },
    forgotPwdClick() {
      this.sendResetPasswordEmail().then(result => {
        let vm = this;
        if (result) {
          this.passwordVisible = false;
          this.forgotPwdVisible = true;
        } else {
          this.$message({
            message: vm.$t('home.pw.error'),
            type: 'error'
          });
        }
      });
    },
    sendResetPasswordEmail() {
      const baseUrl = location.protocol + '//' + location.hostname;
      return apiReq_reset_mt4_password({
        userId: this.uuid,
        mtAccount: this.number,
        baseUrl: baseUrl,
        isDemo: this.isDemo
      }).then(resp => {
        if (resp.data.code == 0) {
          if (resp.data.data) {
            console.log('The reset password email is sent out');
            return Promise.resolve(true);
          } else {
            console.log('failed to sent out the reset password');
          }
        } else {
          console.log('Errors happened when applying for the reset password emails');
        }
        return Promise.resolve(false);
      });
    },
    openReactivateConfirmationPopUp(metaTraderLogin) {
      this.reActivateMetaTraderLogin = metaTraderLogin;
      this.activateVisible = true;
    },
    hideNotice(value) {
      this.isHideNotice = value;
    },
    onResetBalance(row) {
      if (row.credit) {
        this.resetAccountNumber = row.mt4_account;
        this.$refs.liveResetBalanceRef.handleToggleDisplay(row.credit);
      } else {
        this.handleResetBalance(row);
      }
    },
    handleResetBalance(row) {
      apiRestAccountBalance(row ? row.mt4_account : this.resetAccountNumber).then(res => {
        if (res.data.data.code === 0) {
          this.queryMetaTraderAccountDetails();
          this.$message({ message: this.$t('home.resetBalance.resetSuccess'), type: 'success' });
        } else {
          this.$message.error(this.$t(`responseMsg.${res.data.data.code}`));
        }
      });
    },
    copyTrade() {
      this.showCopyTradeDialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/home.scss';
</style>
