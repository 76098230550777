import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions('register', ['actionPoiAuditStatus', 'actionPoaAuditStatus']),
    async init() {
      try {
        await this.actionPoiAuditStatus()
        await this.actionPoaAuditStatus()
      } catch (error) {
        this.$message({ message: this.$t('responseMsg.500'), type: 'error' })
      }
    },
  },
  computed: {
    ...mapState('register', ['poiAuditStatus', 'poaAuditStatus', 'poiPendingInfo', 'poaPendingInfo']),
    ...mapGetters('register', [
      'isPoiEditable',
      'isPoiUnderReview',
      'isPoaEditable',
      'isPoaUnderReview',
      'idPass',
      'poaPass',
    ]),
  },
}
