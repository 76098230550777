<template>
  <el-dialog
    :visible.sync="showResetDialog"
    custom-class="live-reset-balance-dialog"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :modalAppendToBody="false"
    top="0"
  >
    <div class="dialog-top">
      <img src="@/assets/images/dialog/general.png" class="top-icon" />
      <p class="top-title"> {{ $t('home.resetBalance.title1') }} </p>
    </div>
    <div class="dialog-content">
      <span>{{ $t('home.resetBalance.descWait1', {credit: credit}) }}</span>
      <span>{{ $t('home.resetBalance.descWait2') }}</span>
    </div>
    <div class="btn-wrapper">
      <el-button class="btn-default" @click="onCancel">{{ $t('common.button.cancel') }}</el-button>
      <el-button class="btn-blue" @click="onOk">{{ $t('common.button.confirm') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default { 
  name: 'LiveResetBalance',
  props: {
    type: {
      require: true,
      type: String,
      default: 'wait'
    }
  },
  data() {
    return {
      showResetDialog: false,
      credit: null,
    };
  },
  methods: {
    handleToggleDisplay(credit) {
      this.credit = credit;
      this.showResetDialog = !this.showResetDialog;
    },
    onOk() {
      this.handleToggleDisplay();
      this.$emit('confirm');
    },
    onCancel() {
      this.credit = null;
      this.handleToggleDisplay();
    }
  }
};
</script>
<style scoped lang="scss">
/deep/ .live-reset-balance-dialog {
  width: 404px;
  border-radius: 20px;

  .el-dialog__body {
    padding: 45px 55px 35px;
    text-align: center;
    word-break: normal;

    .dialog-top {
      .top-icon {
        display: inline-block;
        width: 60px;
        height: 60px;
        margin-bottom: 10px;
      }

      .top-title {
        min-height: 24px;
        font-size: 16px;
        font-weight: bold;
        color: $blue;
        margin-bottom: 16px;
      }
    }

    .dialog-content {
      font-size: 14px;
      color: $pug-black;
      line-height: 1.2;
      margin-bottom: 20px;
    }

    .btn-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .el-button {
        width: 45%;
      }
    }
  }
}

@media (max-width: 550px) {
  /deep/ .live-reset-balance-dialog {
    width: 100%;

    .el-dialog__body {
      padding: 35px;
    }
  }
}

@media (max-width: 500px) {
  /deep/ .live-reset-balance-dialog {
    width: 100%;

    .el-dialog__body {
      padding: 20px;
    }
  }
}
</style>
